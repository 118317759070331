<template>
  <a class="flex-row align-center" href="/me">
    <img :src="userInfo.avatarImage" alt="用戶頭像" class="user-image" />
    <span class="user-name">{{ userInfo.nickName || "未登錄" }}</span>
  </a>
</template>

<script lang="ts" setup>
import { useStore } from "vuex";
import { computed } from "vue";
import { UserInfo } from "@/api/https/User";
const { getters } = useStore();
const userInfo = computed<UserInfo>(() => {
  return getters.userInfo;
});
</script>

<style scoped>
.user-name {
  font-size: 14px;
  font-weight: 400;
  color: #101010;
  margin-left: 12px;
}
.user-image {
  width: 44px;
  height: 44px;
  border-radius: 50%;
}
</style>
