import axios from "@/api/axios";

export interface StoreRequest {
  key: string;
  longitude: number;
  latitude: number;
  page: number;
  limit: number;
  type: number;
  storeType: number;
}

export const GetStoreListApi = (params: StoreRequest) => {
  return axios.post("/Store/GetStoreList", params);
};

export const getStoreConfigApi = () => {
  return axios.post("/Common/GetConfigV2");
};
export const getDefaultStoreApi = () => {
  return axios.post<{
    id: number;
    storeName: string;
    mobile: string;
    address: string;
  }>("/Store/GetDefaultStore");
};
export const getStoreListApi = (params: StoreRequest) => {
  return axios.post("/Store/GetStoreList", params);
};
export const getStoreDetailApi = (params: any) => {
  return axios.post("/Store/GetShopById", params);
};

export const GetLogisticsApi = (data:{type:number}) => {
  return axios.post("/Store/GetLogistics",data);
};
export const checkMobileApi = (mobile: string)=>{
  return axios.post('/Order/JudgeMobileSendCode?mobile='+mobile,{mobile})
}
