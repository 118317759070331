import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: () => {
      return import(
        /* webpackChunkName: "index" */ "@/views/HomePage/HomPage.vue"
      );
    },
  },
  {
    path: "/carShop",
    name: "carShop",
    component: () => {
      return import(
        /* webpackChunkName: "index" */ "@/views/CarShopPage/CarShopIndex.vue"
      );
    },
  },
  {
    path: "/category",
    children: [
      {
        path: "",
        name: "category",
        meta: {
          title: "商品列表",
        },
        component: () => {
          return import(
            /* webpackChunkName: "index" */ "@/views/CategoryPage/CategoryList.vue"
          );
        },
      },
      // {
      //   path: ":id",
      //   name: "categoryDetail",
      //   component: () => {
      //     return import(
      //       /* webpackChunkName: "index" */ "@/views/CategoryPage/CategoryDetail.vue"
      //     );
      //   },
      // },
    ],
  },
  {
    path: "/payIndex",
    name: "PayIndex",
    component: () => {
      return import(
        /* webpackChunkName: "index" */ "@/views/PayPage/PayIndex.vue"
      );
    },
    meta: {
      title: "订单提交",
    },
  },
  {
    path: "/PaySubmitPage",
    name: "PaySubmitPage",
    component: () => {
      return import(
        /* webpackChunkName: "index" */ "@/views/PayPage/PaySubmitPage.vue"
      );
    },
  },
  {
    path: "/login",
    name: "LoginPage",
    meta: {
      title: "登錄",
    },
    component: () => {
      return import(
        /* webpackChunkName: "index" */ "@/views/Login/LoginPage.vue"
      );
    },
  },
  {
    path: "/search",
    name: "Search",
    meta: {
      title: "搜索",
    },
    component: () => {
      return import("@/views/SearchPage/SearchPage.vue");
    },
  },
  {
    path: "/me",
    name: "me",
    meta: {
      title: "個人中心",
      isLogin: true,
    },
    redirect: "/me/order",
    component: () => {
      return import(
        /* webpackChunkName: "index" */ "@/views/PersonalPage/PersonalPage.vue"
      );
    },
    children: [
      {
        path: "/me/order",
        name: "PersonalOrder",
        component: () => {
          return import("@/views/PersonalPage/PersonalOrder/PersonalOrder.vue");
        },
        meta: {
          title: "我的訂單",
          isLogin: true,
        },
      },
      {
        path: "/me/collect",
        name: "PersonalCollect",
        component: () => {
          return import(
            "@/views/PersonalPage/PersonalCollect/CollectGoods.vue"
          );
        },
        meta: {
          title: "我的收藏",
          isLogin: true,
        },
      },
      {
        path: "/me/history",
        name: "PersonalHistory",
        component: () => {
          return import(
            "@/views/PersonalPage/PersonalHistory/PersonalHistory.vue"
          );
        },
        meta: {
          title: "我的足跡",
          isLogin: true,
        },
      },
      {
        path: "/me/address",
        name: "AddressManage",
        component: () => {
          return import("@/views/PersonalPage/AddressManage/AddressManage.vue");
        },
        meta: {
          title: "地址管理",
          isLogin: true,
          isHide: true,
        },
      },
      {
        path: "/me/afterSale",
        name: "AfterSale",
        component: () => {
          return import(
            "@/views/PersonalPage/PersonalAfterSale/PersonalAfterSale.vue"
          );
        },
        meta: {
          title: "我的售後",
          isLogin: true,
        },
      },
    ],
  },
  {
    path: "/set",
    name: "set",
    redirect: "/set/userInfo",
    component: () => {
      return import("@/views/PersonalPage/PersonalPage.vue");
    },
    meta: {
      title: "設置中心",
      isLogin: true,
    },
    children: [
      {
        path: "/set/userInfo",
        name: "UserInfo",
        component: () => {
          return import("@/views/UserSetting/SetUserInfo/SetUserInfo.vue");
        },
        meta: {
          title: "個人資料",
          isLogin: true,
        },
      },
      {
        path: "/set/password",
        name: "SetPassword",
        component: () => {
          return import("@/views/UserSetting/SetPassword/SetPassword.vue");
        },
        meta: {
          title: "重置密碼",
          isLogin: true,
          isHide: false,
        },
      },
    ],
  },
  {
    path: "/goods/:id",
    name: "ProductDetails",
    component: () => {
      return import("@/views/ProductDetails/ProductDetails.vue");
    },
  },
  {
    path: "/orderDetail/:id",
    name: "orderDetail",
    meta: {
      title: "訂單詳情",
      isLogin: true,
    },
    component: () => {
      return import("@/views/OrderDetail/OrderDetail.vue");
    },
  },
  {
    path: "/expressInfo/:id",
    name: "ExpressInfo",
    meta: {
      title: "物流信息",
      isLogin: true,
    },
    component: () => {
      return import("@/views/ExpressInfo/ExpressInfo.vue");
    },
  },
  {
    path: "/orderComment/:id",
    name: "orderComment",
    meta: {
      title: "訂單評價",
      isLogin: true,
    },
    component: () => {
      return import("@/views/OrderComment/OrderComment.vue");
    },
  },
  {
    path: "/submitAfterSale/:id",
    name: "SubmitAfterSale",
    meta: {
      title: "提交售後",
      isLogin: true,
    },
    component: () => {
      return import("@/views/AfterSale/SubmitPage/SubmitPage.vue");
    },
  },
  {
    path: "/afterSaleDetail/:id",
    name: "AfterSaleDetail",
    meta: {
      title: "售後詳情",
      isLogin: true,
    },
    component: () => {
      return import("@/views/AfterSale/DetailPage/DetailPage.vue");
    },
  },
  {
    path: "/pages/share/jump/jump",
    name: "DeShare",
    meta: {
      title: "解析鏈接",
      isLogin: false,
    },
    component: () => {
      return import("@/views/DeShare/DeShare.vue");
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
