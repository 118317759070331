import { Message } from "@arco-design/web-vue";
import { getToken } from "@/utils/auth";
import { DirectiveBinding } from "vue";

const { warning } = Message;

export const login = (binding: DirectiveBinding<any>) => {
  const token = getToken();
  if (!token) {
    warning("请先登录");
  } else {
    binding.value();
  }
};
