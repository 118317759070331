import router from "./router/index";
import store from "@/store";
import { getToken } from "@/utils/auth";
router.beforeEach(async (to, from, next) => {
  if (to.meta.title) {
    document.title = getPageTitle(to.meta.title as string);
  }
  await store.dispatch("getAreaCodeList");
  if (to.path === "/login") {
    next();
  } else {
    const hasToken = getToken();
    if (hasToken) {
      try {
        await store.dispatch("getUserInfo");
        await store.dispatch("getAddressList");
        await store.dispatch("getStoreList");
        await store.dispatch("getStoreConfig");
        await store.dispatch("getStoreConfig");
      } catch (e) {
        console.log(e);
      }
      next();
    } else if (!to.meta.isLogin) {
      next();
    } else {
      next("/login");
    }
  }
});

const title = "Hi購";
export default function getPageTitle(pageTitle: string) {
  if (pageTitle) {
    return `${pageTitle} - ${title}`;
  }
  return `${title}`;
}
