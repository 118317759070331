<template>
  <div
    :style="props.item.selected ? 'border: 1px solid #ff761a;' : ''"
    class="cat_item_wrapper flex-row align-center"
  >
    <a-checkbox
      :model-value="props.item.selected"
      size="small"
      @change="emit('changeSelect', { record: props.item, $event })"
    ></a-checkbox>
    <a-image
      :src="props.item.products.images"
      class="cover"
      height="150px"
      width="150px"
    ></a-image>
    <div style="margin-right: 87px">
      <div class="goods_title">
        {{ props.item.products.name }}
      </div>
      <div class="size">規格：{{ props.item.products.spesDesc }}</div>
    </div>
    <div>
      <div
        style="text-decoration: line-through; font-size: 14px; color: #8f8f8f"
      >
        {{ props.item.priceCurrency }}{{ props.item.products.mktprice }}
      </div>
      <div class="money">
        {{ props.item.priceCurrency }}{{ props.item.products.price }}
      </div>
    </div>
    <a-input-number
      :default-value="props.item.nums"
      mode="button"
      style="width: 160px; margin: 0 87px 0 142px"
      @change="changeNum"
    ></a-input-number>
    <a-popconfirm content="確定删除嗎？" @ok="removeGoodsForCar">
      <a-button style="margin-left: 16px;color: #FF" type="text">
        <template #icon>
          <icon-delete />
        </template>
        删除
      </a-button>
    </a-popconfirm>
  </div>
</template>

<script lang="ts" setup>
import { CarShopItemType } from "@/components/CarShopDraw/type";
import { RemoveGoodsForCarApi } from "@/api/https/User";
import { defineEmits } from "vue";
import { Message } from "@arco-design/web-vue";
const emit = defineEmits(["removeGoodsForCar", "changeSelect", "changeNums"]);
const props = defineProps<{ item: CarShopItemType }>();
const { success } = Message;
const removeGoodsForCar = async () => {
  const data = { id: props.item.id };
  await RemoveGoodsForCarApi(data);
  success("删除成功");
  emit("removeGoodsForCar", { id: props.item.id });
};
const changeNum = (e) => {
  console.log(props.item.productId);
  emit("changeNums", { id: props.item.productId, nums: e });
};
</script>
<script lang="ts">
export default {
  name: "CarShopItem",
};
</script>
<style lang="scss" scoped>
.cat_item_wrapper {
  padding: 24px;
  //width: 1200px;
  background: #f5f5f5;
  opacity: 1;
  margin-bottom: 20px;
}

.cover {
  width: 120px;
  height: 120px;
  border-radius: 10px;
  margin: 0 32px;
}

.goods_title {
  width: 287px;
  height: 55px;
  font-size: 16px;
  font-weight: 400;
  color: #101010;
  line-height: 30px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.size {
  height: 22px;
  font-size: 14px;

  font-weight: 400;
  color: #474747;
  line-height: 30px;
  margin-top: 16px;
}

.money {
  font-size: 16px;
  font-weight: bold;
  color: #ff761a;
  line-height: 30px;
}
</style>
