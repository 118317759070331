<template>
  <div
    class="flex-row align-center"
    style="cursor: pointer; position: relative"
    @click="openDraw"
  >
    <img :src="src" style="height: 26px; width: 25px" />
    <span class="name">購物車</span>
    <icon-caret-down />
    <divc
      v-if="carShopList.length > 0"
      style="
        width: 24px;
        height: 24px;
        text-align: center;
        line-height: 24px;
        background-color: #ff7213;
        color: white;
        border-radius: 50%;
        position: absolute;
        right: 4px;
        top: -6px;
        font-size: 12px;
      "
    >
      {{ carShopList.length }}
    </divc>
  </div>
  <a-drawer
    :visible="visible"
    placement="right"
    width="1200px"
    @cancel="visible = false"
    unmountOnClose
  >
    <template #header>
      <div class="flex-row align-center justify-center" style="width: 100%">
        <div class="title">購物車（全部{{ carShopList.length }}）</div>
      </div>
    </template>
    <a-scrollbar class="scrollbar">
      <a-empty v-if="carShopList.length === 0"></a-empty>
      <CarShopItem
        v-else
        v-for="item in carShopList"
        :key="item.id"
        :item="item"
        @changeSelect="changeSelect"
        @removeGoodsForCar="removeGoods"
        @changeNums="changeNums"
      />
    </a-scrollbar>
    <template #footer>
      <div class="flex-row align-center justify-between" style="width: 100%">
        <a-checkbox
          :model-value="isAllSelected"
          @change="handleChangeAllSelect"
          style="width: 70px"
          >全選</a-checkbox
        >
        <a-popconfirm content="確定删除嗎？" @ok="removeSelectGoods">
          <a-button type="text">
            <template #icon>
              <icon-delete />
            </template>
            刪除
          </a-button>
        </a-popconfirm>
        <div class="flex-1"></div>
        <div class="flex-row align-center justify-between">
          <div class="flex-row align-center justify-center label">
            已選商品<span class="select_goods_count">{{
              selectedGoodsCount
            }}</span>
            件
          </div>
          <div
            class="flex-row align-center label"
            style="margin-left: 48px; margin-right: 28px"
          >
            <span>合計（不含運費）</span
            ><span class="select_goods_count">{{
              selectGoodsMoney.toFixed(2)
            }}</span>
          </div>
          <a-button type="primary" size="large" @click="toPay" style="background-color: #FF7213">結算</a-button>
        </div>
      </div>
    </template>
  </a-drawer>
</template>

<script lang="ts" setup>
import CarShopItem from "@/components/CarShopDraw/Item.vue";
import src from "./icon/car-shop.png";
import { ref, computed, onMounted } from "vue";
import { CarShopItemType } from "@/components/CarShopDraw/type";
import {
  getCarShopListApi,
  RemoveGoodsForCarApi,
  SetCartNumApi,
} from "@/api/https/User";
import { getToken } from "@/utils/auth";
const carShopList = ref<CarShopItemType[]>([]);
const visible = ref(false);
const selectGoodsMoney = computed(() => {
  return carShopList.value
    .filter((item) => item.selected)
    .reduce((pre, cur) => {
      return pre + Number(cur.products.price) * Number(cur.nums);
    }, 0);
});
const selectedGoodsCount = computed(() => {
  return carShopList.value.filter((item) => item.selected).length;
});
const isAllSelected = computed(() => {
  return carShopList.value.every((item) => item.selected);
});
const changeNums = ({ id, nums }: { id: number; nums: number }) => {
  carShopList.value.forEach(async (item, index) => {
    if (item.productId === id) {
      item.nums = nums;
      await SetCartNumApi({ id, nums });
      carShopList.value[index] = item;
    }
  });
};
const toPay = () => {
  const ids = carShopList.value
    .filter((item) => item.selected)
    .map((item) => item.id)
    .join(",");
  window.location.href = "/payIndex?cartIds=" + ids;
  // router.push();
  // commit(
  //   "SET_CAR_LIST",
  //   carShopList.value.filter((item) => item.selected)
  // );
  visible.value = false;
};
const removeGoods = ({ id }: { id: number }) => {
  carShopList.value = carShopList.value.filter((item) => item.id !== id);
};
const removeSelectGoods = () => {
  Promise.all(
    carShopList.value
      .filter((item) => item.selected)
      .map((item) => {
        return RemoveGoodsForCarApi({
          id: item.id,
        });
      })
  ).then((res) => {
    console.log(res);
    carShopList.value = carShopList.value.filter((item) => !item.selected);
  });
};
const openDraw = () => {
  visible.value = !visible.value;
  getCarList();
};
const getCarList = async () => {
  try {
    let data = {
      display: "all",
    };
    const res = await getCarShopListApi(data);
    carShopList.value = res.data?.list.map((item) => {
      return {
        ...item,
        priceCurrency: "$",
        selected: false,
      };
    });
  } catch (e) {
    console.log(e);
  }
};
const handleChangeAllSelect = (e: boolean) => {
  if (e) {
    carShopList.value.forEach((item) => {
      item.selected = true;
    });
  } else {
    carShopList.value.forEach((item) => {
      item.selected = false;
    });
  }
};
const changeSelect = ({ record }: { record: CarShopItemType }) => {
  record.selected = !record.selected;
};
onMounted(() => {
  const token = getToken();
  if (token) {
    getCarList();
  }
});
</script>
<script lang="ts">
export default {
  name: "CarShopDraw",
};
</script>
<style lang="scss" scoped>
.label {
  font-size: 16px;

  font-weight: 400;
  color: #121720;
}
.title {
  text-align: center;
  font-size: 14px;
  font-family: TsangerYuYangT, TsangerYuYangT;
  font-weight: normal;
  color: #121720;
}
.name {
  margin: 0 6px 0 8px;
  height: 20px;
  font-size: 14px;
  width: 50px;
  font-weight: 400;
  color: #2b2b2b;
}
.desc {
  height: 28px;
  font-size: 20px;

  font-weight: 400;
  color: #121720;
}
.money {
  height: 40px;
  font-size: 28px;

  font-weight: 400;
  color: #ff761a;
}
.select_goods_count {
  font-size: 16px;
  font-weight: bold;
  color: #ff761a;
}
.scrollbar {
  height: 800px;
  overflow: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
}
</style>
