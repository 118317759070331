import axios, {
  AxiosInstance,
  AxiosError,
  AxiosRequestConfig,
  AxiosResponse,
} from "axios";
import { Message } from "@arco-design/web-vue";
import { useStore } from "vuex";
import router from "@/router";
import Cookies from "js-cookie";
// 数据返回的接口
// 定义请求响应参数，不含data
interface Result {
  code: number;
  msg: string;
  methodDescription: string;
  status: boolean;
}
const { error } = Message;
// 请求响应参数，包含data
interface ResultData<T = any> extends Result {
  data?: T;
  msg: string;
}
const URL = "/Api";
enum RequestEnums {
  TIMEOUT = 20000,
  OVERDUE = 600, // 登录失效
  FAIL = 999, // 请求失败
  SUCCESS = 200, // 请求成功
}
const config = {
  // 默认地址
  baseURL: URL as string,
  // 设置超时时间
  timeout: RequestEnums.TIMEOUT,
  // 跨域时候允许携带凭证
  withCredentials: true,
};

class RequestHttp {
  // 定义成员变量并指定类型
  service: AxiosInstance;
  public constructor(config: AxiosRequestConfig) {
    // 实例化axios
    this.service = axios.create(config);

    /**
     * 请求拦截器
     * 客户端发送请求 -> [请求拦截器] -> 服务器
     * token校验(JWT) : 接受服务器返回的token,存储到vuex/pinia/本地储存当中
     */
    this.service.interceptors.request.use(
      (config: any) => {
        const token = localStorage.getItem("token") || "";
        return {
          ...config,
          headers: {
            Authorization: "Bearer " + token, // 请求头中携带token信息
          },
        };
      },
      (error: AxiosError) => {
        // 请求报错
        Promise.reject(error);
      }
    );

    /**
     * 响应拦截器
     * 服务器换返回信息 -> [拦截统一处理] -> 客户端JS获取到信息
     */
    this.service.interceptors.response.use(
      (response: AxiosResponse) => {
        const { data } = response; // 解构
        // 全局错误信息拦截
        if (typeof data.status === "boolean") {
          if (data.status !== true) {
            if (data.code === 14007) {
              Cookies.remove("mall_token");
              localStorage.removeItem("token");
              localStorage.removeItem("user_info");
              const url = window.location.pathname;
              window.location.href = "/login?redirect="+url;
            } else {
              error(data.msg);
              return Promise.reject(data);
            }
            // return Promise.reject(data);
          }
        }
        return data;
      },
      (error: AxiosError) => {
        const { response } = error;
        if (response) {
          this.handleCode(response.status);
        }
        if (!window.navigator.onLine) {
          // 可以跳转到错误页面，也可以不做操作
          // return router.replace({
          // path: '/404'
          // });
        }
      }
    );
  }
  handleCode(code: number): void {
    switch (code) {
      case 401:
        break;
      default:
        break;
    }
  }

  // 常用方法封装
  get<T>(url: string, params?: object): Promise<ResultData<T>> {
    return this.service.get(url, { params });
  }
  post<T>(url: string, params?: object): Promise<ResultData<T>> {
    return this.service.post(url, params);
  }
  put<T>(url: string, params?: object): Promise<ResultData<T>> {
    return this.service.put(url, params);
  }
  delete<T>(url: string, params?: object): Promise<ResultData<T>> {
    return this.service.delete(url, { params });
  }
}

// 导出一个实例对象
export default new RequestHttp(config);
