import { createStore } from "vuex";
import {
  GetAddressListApi,
  GetUserInfoApi,
  LoginApi,
  UserInfo,
} from "@/api/https/User";
import { getToken, setToken } from "@/utils/auth";
import {
  getStoreConfigApi,
  GetStoreListApi,
  StoreRequest,
} from "@/api/https/Store";
import { getAreaListApi } from "@/api/https/System";
import router from "@/router";
export default createStore({
  state: {
    orderListStatus: {
      0: "全部",
      1: "待審核",
      2: "待發貨",
      3: "待收貨",
      4: "待評價",
    },
    orderStatus: {
      0: "待審核",
      1: "待發貨",
      2: "待收貨",
      3: "待評價",
      4: "已完成",
      5: "已關閉",
      6: "已刪除",
      7: "已取消",
      8: "已退款",
      9: "已拒絕",
      10: "已退貨",
    },
    deliveryList: {
      1: "快遞",
      2: "宅配",
      3: "提貨點自提",
    },
    token: "",
    userInfo: {
      avatarImage: require("@/assets/default-avatar.png"),
    },
    addressList: [],
    storeList: [],
    carList: [],
    storeConfig: {},
    serviceList: [],
    serviceId: "",
    serviceInfo: {},
    sessionId: "",
    areaCodeList: [],
  },
  getters: {
    token(state) {
      return (
        state.token || getToken() || localStorage.getItem("mall_token") || ""
      );
    },
    storeList(state) {
      return state.storeList;
    },
    userInfo(state) {
      return (
        JSON.parse(localStorage.getItem("user_info") as string) ||
        state.userInfo
      );
    },
    addressList(state) {
      return state.addressList;
    },
    carShopList(state) {
      return state.carList;
    },
    storeConfig(state) {
      return state.storeConfig;
    },
    serviceList(state) {
      return state.serviceList;
    },
    serviceId(state) {
      return state.serviceId;
    },
    serviceInfo(state) {
      return state.serviceInfo;
    },
    sessionId(state) {
      return state.sessionId;
    },
    areaCodeList(state) {
      return state.areaCodeList;
    },
  },
  mutations: {
    SET_TOKEN(state, token: string) {
      setToken(token);
      state.token = token;
    },
    SET_USER_INFO(state, userInfo: UserInfo) {
      state.userInfo = userInfo;
    },
    SET_ADDRESS_LIST(state, addressList) {
      state.addressList = addressList;
    },
    SET_STORE_LIST(state, data) {
      state.storeList = data;
    },
    SET_CAR_LIST(state, data) {
      state.carList = data;
    },
    SET_STORE_CONFIG(state, data) {
      state.storeConfig = data;
    },
    SET_SERVICE_LIST(state, data) {
      state.serviceList = data;
    },
    SER_SERVICE_ID(state, data) {
      state.serviceId = data;
    },
    SET_SERVICE_INFO(state, data) {
      state.serviceInfo = data;
    },
    set_sessionId(state, data) {
      state.sessionId = data;
    },
    set_areaCodeList(state, data) {
      state.areaCodeList = data;
    },
  },
  actions: {
    async login(context, data) {
      try {
        const res = await LoginApi(data);
        localStorage.setItem("token", <string>res.data?.token);
        context.commit("SET_TOKEN", res.data?.token);
        await context.dispatch("getUserInfo");
        if(data.redirect){
          router.push(data.redirect)
        }else {
          router.push("/")
        }
      } catch (e) {
        throw Error();
      }
    },
    async getUserInfo(context) {
      const res = await GetUserInfoApi();
      if (typeof res.data !== "object") {
        return;
      }
      localStorage.setItem("user_info", JSON.stringify(res.data));
      context.commit("SET_USER_INFO", res.data);
    },
    async logout(context) {
      localStorage.removeItem("token");
      context.commit("SET_TOKEN", "");
    },
    async getAddressList(context) {
      const res = await GetAddressListApi();
      context.commit("SET_ADDRESS_LIST", res.data);
    },
    async getStoreConfig(context) {
      const res = await getStoreConfigApi();
      context.commit("SET_STORE_CONFIG", res.data);
    },
    async getStoreList(context) {
      const data = {
        page: 1,
        limit: 10,
        type: 2,
      };
      const res = await GetStoreListApi(<StoreRequest>data);
      context.commit("SET_STORE_LIST", res.data);
    },
    async getAreaCodeList(context) {
      const res = await getAreaListApi({ isDelete: false });
      context.commit("set_areaCodeList", res.data);
    },
  },
  modules: {},
});
