import axios from "@/api/axios";

export interface LoginForm {
  mobile: string;
  code: string;
  type: number;
}
export interface LoginResponse {
  expires_in: number;
  token: string;
  success: boolean;
  token_type: string;
}
export interface UserInfo {
  id: number;
  userName: string;
  nickName: string;
  avatarImage: string;
  email: string;
  mobile: string;
  gender: number;
  birthday: string;
  last_login_time: string;
  last_login_ip: string;
  status: number;
  create_time: string;
  update_time: string;
  remark: string;
  role_id: number;
  role_name: string;
  role_key: string;
  passWord: string;
}
export interface CarResultType {
  userId: number;
  goodsAmount: number;
  amount: number;
  orderPromotionMoney: number;
  goodsPromotionMoney: number;
  couponPromotionMoney: number;
  promotionList: object;
  costFreight: number;
  weight: number;
  coupon: Array<any>;
  type: number;
  point: number;
  pointExchangeMoney: number;
  list: Array<any>;
  goodsPromotionMoneyOld: number;
  freightCalculationRules: string;
}
export interface Address {
  address: string;
  name: string;
  mobile: string;
  id: number;
  userId: number;
  mobileAreaCodeId: number;
  isDefault: boolean;
  areaName: string;
  areaId: number;
  selected?: boolean;
}

export interface editUserInfoParamApi {
  sex: number;
  birthday: string;
  nickname: string;
}

export interface editUserPwdParamApi {
  pwd?: string;
  newpwd: string;
  repwd: string;
}

export const LoginApi = (data: LoginForm) => {
  return axios.post<LoginResponse>("/User/login", data);
};
export const GetUserInfoApi = () => {
  return axios.post<UserInfo>("/User/GetUserInfo");
};
export const getCarShopListApi = (data: {
  display?: string;
  receiptType?: number;
  ids?: string;
  objectId: number;
  type: number;
  point: number;
  couponCode: string;
  areaId: number;
}) => {
  return axios.post<CarResultType>("/Cart/GetList", data);
};
export const SetCartNumApi = (data: { id: number; num: number }) => {
  return axios.post("/Cart/SetCartNum", data);
};
export const RemoveGoodsForCarApi = (data: { id: number }) => {
  return axios.post<CarResultType>("/Cart/DoDelete", data);
};
export const GetAddressListApi = () => {
  return axios.post<Array<Address>>("/User/GetUserShip");
};

export const changeAvatar = (value: string) => {
  return axios.post("/User/ChangeAvatar", { id: value });
};

export const editInfo = (params: editUserInfoParamApi) => {
  return axios.post("/User/EditInfo", params);
};

export const editUserPwd = (params: editUserPwdParamApi) => {
  return axios.post("/User/EditPwd", params);
};
export const ForgetPwdApi = (data: {
  mobile: string;
  code: string;
  newpwd: string;
  repwd: string;
  shipMobileAreaCodeId: number|null;
}) => {
  return axios.post("/User/ForgetPwd", data);
}
