<template>
  <div>
    <ShopHeader
      style="position: fixed; top: 0; left: 0; width: 100%; z-index: 999"
    />
    <div style="height: 140px"></div>
    <div id="m" style="display: none"></div>
    <a-back-top target-container="#m" />
    <div class="app-content">
      <router-view />
    </div>
<!--    <div-->
<!--      style="-->
<!--        padding: 10px;-->
<!--        border-radius: 50%;-->
<!--        position: fixed;-->
<!--        top: 50%;-->
<!--        right: 0;-->
<!--        cursor: pointer;-->
<!--      "-->
<!--      @click="openService"-->
<!--    >-->
<!--      <img-->
<!--        src="@/assets/icon/serivce-icon.png"-->
<!--        style="width: 50px; height: 50px"-->
<!--      />-->
<!--    </div>-->
  </div>
</template>
<script lang="ts">
import { defineComponent, onMounted } from "vue";
import ShopHeader from "@/components/ShopHeader/Index.vue";
// import { useStore } from "vuex"; // @ is an alias to /src
// import axios from "axios";
// import { getSessionIdApi } from "@/api/https/System";

export default defineComponent({
  name: "HomeView",
  components: {
    ShopHeader,
  },
  setup() {
    // const { commit, getters } = useStore();
    // const toListen = async () => {
    //   const res = await axios.get(`/apis?_t==${new Date().getTime()}`);
    //   const list = Object.keys(res.data.result);
    //   commit("SET_SERVICE_LIST", list);
    //   commit("SET_SERVICE_INFO", res.data.result);
    // };
    // const openService = async () => {
    //   await toListen();
    //   const keys = Object.values(getters.serviceList);
    //   const nowId = getters.serviceId;
    //   const id = keys[Math.floor(Math.random() * keys.length)] as string;
    //   if (!id) {
    //     alert("暂无客服在线");
    //     return;
    //   }
    //   const { Name } = getters.serviceInfo[id];
    //   if (!nowId) {
    //     const res = await getSessionIdApi({ id: id, data: Name });
    //     commit("set_sessionId", res.data);
    //   }
    //   commit("SER_SERVICE_ID", id);
    //   const win = window.open(
    //     // `http://192.168.10.32:8081/chatIndex?kefu_id=${id}`
    //     `https://csm.hgb.tw/chatIndex?kefu_id=${id}&_t=${new Date().getTime()}`
    //   ) as any;
    //   win.addEventListener("beforeunload", (e: any) => {
    //     console.log(e);
    //   });
    // };
    onMounted(() => {
      // toListen();
    });
    return {
      // toListen,
      // openService,
    };
  },
});
</script>
<style lang="scss">
@import "@/static/style/style.scss";
.app-content {
  max-width: 1400px;
  margin: 0 auto;
}
</style>
