import axios from "@/api/axios";

export interface SendMailDataType {
  shipMobileAreaCodeId: number;
  toUser: string;
  type: string;
}
export const sendEmail = (data: SendMailDataType) => {
  return axios.post("/User/SendCode", data);
};
export const getSessionIdApi = (data: any) => {
  return axios.post("/User/GetSessionId", data);
};
//获取区号列表
export const getAreaListApi = (data: any) => {
  return axios.post("/CoreCmsAreaCode/GetPageList", data);
};
export const changePasswordApi = (data: any) => {
  return axios.post("/User/ForgetPwd", data);
};
export const GetUserShipApi = (data:{type:number}) => {
  return axios.post("/User/_GetUserShip",data);
}
export const SaveUserShipApi = (data: { type: number;data: any }) => {
  return axios.post("/User/_SaveUserShip", data);
}
