import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "@/utils/instruct";
import "@arco-design/web-vue/dist/arco.css";
// main.ts文件
// 引入主题(注意这里要先安装后，才能导入，不然不生效)
import "@arco-themes/vue-shangcheng/index.less";

import "/src/assets/style.css";
// 额外引入图标库
import ArcoVueIcon from "@arco-design/web-vue/es/icon";
import ArcoVue from "@arco-design/web-vue";
import { login } from "@/utils/instruct";
import "@/permission";
createApp(App)
  .use(store)
  .use(ArcoVue)
  .use(ArcoVueIcon)
  .use(router)
  .directive("login", {
    mounted(el, binding, vnode, prevVnode) {
      el.addEventListener("click", login(binding));
    },
    // 离开一定要销毁卸载
    unmounted(el, binding, vnode) {
      el.removeEventListener("click", login);
    },
  })
  .mount("#app");
