<template>
  <div style="position: fixed; top: 0; left: 0; width: 100%; z-index: 999">
    <div class="top-bar arco-bar">
      <div
        style="
          display: flex;
          align-items: center;
          min-width: 1200px;
          justify-content: space-between;
        "
      >
        <div class="flex-row align-center">
          <div class="menus-index">
            <a href="/">
              <span class="menus-label">首頁</span>
            </a>
          </div>
          <!--          <div style="padding-left: 24px">-->
          <!--            <a-space size="large">-->
          <!--              <a-dropdown>-->
          <!--                <div>-->
          <!--                  <icon-language />-->
          <!--                  <span class="menus-label">语言</span>-->
          <!--                  <icon-caret-down />-->
          <!--                </div>-->
          <!--                <template #content>-->
          <!--                  <a-doption>中文</a-doption>-->
          <!--                  <a-doption>繁体</a-doption>-->
          <!--                </template>-->
          <!--              </a-dropdown>-->
          <!--            </a-space>-->
          <!--          </div>-->
          <a href="/me">
            <span class="menus-label" style="margin-left: 32px">{{
              userInfo.nickName || "未登錄"
            }}</span>
          </a>
        </div>
        <div>
          <a class="menus-label" href="/carShop">購物車</a>
          <span class="arco-size-hx">|</span>
          <a href="/me/order"> <span class="menus-label">我的訂單</span></a>
        </div>
      </div>
    </div>
    <div
      class="bg-white pad-60-100"
      :style="{ 'max-width': isExpanded ? '100%' : '1400px' }"
    >
      <div style="max-width: 1200px; margin: 0 auto">
        <a-row :gutter="12" style="display: flex;align-items: center">
          <a-col :span="4">
            <a style="display: flex; align-items: center" href="/">
              <img :src="logoIcon" width="101" height="101" /> </a
          ></a-col>
          <a-col :span="13"
            ><div class="search-box-wrap">
              <a-input class="search-box" size="large" v-model="keyword">
                <template #suffix>
                  <a-button
                    size="large"
                    type="primary"
                    status="warning"
                    @keyup.enter="handleSearchClick"
                    @click="handleSearchClick"
                    >搜索</a-button
                  >
                </template>
              </a-input>
            </div></a-col
          >
          <a-col :span="7">
            <div
              style="
                display: flex;
                justify-content: space-between;
                margin-left: 45px;
              "
            >
              <CarShopDraw />
              <UserInfoForHeader></UserInfoForHeader>
            </div>
          </a-col>
        </a-row>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import logoIcon from "../../assets/index_logo.png";
import CarShopDraw from "../CarShopDraw/Index.vue";
import { useStore } from "vuex";
import { computed, ref, onMounted, onUnmounted } from "vue";
import { UserInfo } from "@/api/https/User";
import UserInfoForHeader from "@/components/UserInfoForHeader/UserInfoForHeader.vue";
import { useRouter } from "vue-router";

const { getters } = useStore();
const userInfo = computed<UserInfo>(() => {
  return getters["userInfo"];
});
const isExpanded = ref(false); // 控制样式的标志
// 定义一个响应式样式对象
// const bgWhiteStyle = computed(() => ({
//   maxWidth: isExpanded.value ? "100%" : "1400px",
// }));
const router = useRouter();
const keyword = ref("");
const handleSearchClick = () => {
  router.push({ name: "Search", query: { key: keyword.value } });
};
const handleScroll = () => {
  const scrollDistance = window.scrollY;
  const threshold = 100; // 设定的阈值，可以根据需要调整
  isExpanded.value = scrollDistance > threshold;
};

onMounted(() => {
  window.addEventListener("scroll", handleScroll);
});

onUnmounted(() => {
  window.removeEventListener("scroll", handleScroll);
});
</script>
<script lang="ts">
export default {
  name: "ShopHeader",
};
</script>

<style lang="scss" scoped>
.top-bar.arco-bar {
  display: flex;
  align-items: center;
  justify-content: space-around;
  line-height: 40px;
  background-color: #ff761a;
  color: white;
  height: 40px;
  padding: 0; // 根据需要调整填充
}

.top-search {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 1200px;
  background-color: #ff761a;
  color: #2b2b2b;
  height: 108px;
  padding: 0; // 根据需要调整填充
}
.menus-label {
  margin-left: 17px;
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
}
.menus-index {
  background-image: url("../../assets/icon_house.png");
  background-size: 13px 14px;
  background-repeat: no-repeat;
  background-position: 1% 50%;
}
.search-container {
  display: flex;
  align-items: center; /* 垂直居中 */
  padding-right: 30px;
}
.search-box-wrap {
  overflow: hidden;
  width: 670px;
  border: 1px solid #ff761a;
  border-radius: 4px;
  background-color: #fff; /* 搜索框背景色 */
}
.search-box {
  background-image: url("../../assets/icon_search.png");
  background-size: 19px 19px;
  background-repeat: no-repeat;
  background-position: 2% 50%;
  background-color: #fff;
  padding: 0 0 0 45px;
  color: #333; /* 搜索框文本颜色 */
}
.search-user {
  display: flex;
  justify-content: space-between;
  text-align: center;
  align-items: center;
}

.bg-white {
  background-color: #ffffff;
  box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.05);
  //border-bottom: 1px solid #;
  transition: max-width 0.3s ease; // 平滑过渡效果
  margin: 0 auto;
}

.pad-60-100 {
  height: 100px;
}

.search-text {
  padding-left: 10px;
  font-size: 24px;
  font-weight: bold;
  color: #2b2b2b;
}
.user-image {
  width: 52px;
  height: 52px;
  border-radius: 50%;
}
.grid-demo {
  text-align: center;
}

.top-left.arco-left {
}

.top-right.arco-right {
}

.arco-link {
  /* 设置字体大小 */
  font-size: 14px;
  /* 设置文字颜色为白色 */
  color: white;
  /* 取消默认的点击样式 */
  outline: none;
}
.icon-home {
  background-image: url("../../assets/icon_house.png");
  background-size: cover;
  width: 20px;
  height: 20px;
  margin-right: 5px;
}
.arco-name {
  font-size: 14px;
  padding-left: 32px;
}

.arco-button {
  font-size: 14px;
}

.arco-text {
  font-size: 14px;
}

.arco-size-hx {
  padding: 0 16px;
}
</style>
